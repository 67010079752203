.splashMainContainer {
    margin-top: 40px;
    border:1px solid #000;
    height: 500px;
}
.splashTitle{
    margin-top: 20px;
    font-family:'Times New Roman', Times, serif;
    font-weight: 700;
    font-size: 20px;
    color: #00817f;
}
.splashPara{
    font-family:'Times New Roman', Times, serif;
    margin-top: 5px;
    font-size: 18px;
}