
.dashboard-title{
    font-family: 'Times New Roman', Times, serif;
    color: black;
    font-size: 20px;
    font-weight: 800;
    margin-top: 40px;
}
.counter-box{
    width: 60%;
    height: 200px;
    background-color: #00817f;
    display: block;
    justify-content: center;
    align-items: center;
}
.num-Counter{
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #FFFFFF;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 800;
}
.counter-text{
      font-size: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #FFFFFF;
    font-family: 'Times New Roman', Times, serif;
    
}
.fullname-dashboard{
    color: #00817f;
    font-family: 'Times New Roman', Times, serif;
}
.mobile-user-dashboard-text{
    font-size: x-large;
    font-family: 'Times New Roman', Times, serif;
    margin-top: 40px;
    color: #000;
}
.mobile-fullname{
    font-family: 'Times New Roman', Times, serif;
    color: black;
}
.dasboard-mobile-container{
    width: 100%;
    height: 250px;
    background-color: #00817f;
}

.dashboard-mobile-menu-container{
    width: 100%;
    height: auto;
    border: 1px solid grey;
    margin-top: 10px;
}
.item-menu{
    width: 100%;
    border-bottom: 1px solid grey;
    display: flex;
    align-items: center;
    padding: 10px;
    position: relative;
}
.menu-link{
    font-family: 'Times New Roman', Times, serif;
    text-decoration: none;
    color: #000;

}