.signin-header{
    margin-top: 20px;
    font-family: 'Times New Roman', Times, serif;
    font-size: 18px;
    font-weight: 800;
}
.loginBtn{
    width: 100%;
    height: 45px;
    background-color: #00817f;
}
.forgotPassword{
    width: 100%;
    text-align: left;
    font-family: 'Times New Roman', Times, serif;
    font-size: 16px;
    color: grey;
    text-decoration: none;
}
.signup_link{
    width: 100%;
    text-align: left;
    font-family: 'Times New Roman', Times, serif;
    font-size: 18px;
    color: black;
    text-decoration: none;
   
}
.signupMainContainer{
    height: 700px;
    margin-top: 40px;
    border:1px solid #000;
    position: relative;
}
.signupBtn{
      width: 100%;
    height: 45px;
    background-color: #00817f;
}